import Vue from 'vue'
import Router from 'vue-router'
import ClientsDetail from "../views/ClientsDetail.vue";

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const ClientsEditItem = () => import('@/views/ClientsEditItem')

// Views
const DashboardDetail = () => import('@/views/DashboardDetail')
const WalletHistory = () => import('@/views/WalletHistory')
const Dashboard = () => import('@/views/Dashboard')
const PhoneFile = () => import('@/views/PhoneFile')
const PhoneClients = () => import('@/views/PhoneClients')
const Clients = () => import('@/views/Clients')
const DownloadFile = () => import('@/views/DownloadFile')
const Variables = () => import('@/views/Variables')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// // Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)
const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'Общие данные',
                    component: Dashboard
                },
                {
                    path: 'dashboard/:id',
                    name: 'Общие данные',
                    component: DashboardDetail
                },
                {
                    path: 'clients',
                    name: 'Клиенты',
                    component: Clients
                },
                {
                    path: 'clients/:id',
                    name: 'Клиенты',
                    component: ClientsDetail
                },
                {
                    path: 'clients/item/:id',
                    name: 'Детали клиента ',
                    component: ClientsEditItem
                },
                {
                    path: 'download-file',
                    name: 'Загрузить файл',
                    component: DownloadFile
                },
                {
                    path: 'phones-clients',
                    name: 'Справочник',
                    component: PhoneClients
                },
                {
                    path: 'phones',
                    name: 'Справочник',
                    component: PhoneFile
                },
                {
                    path: 'variables',
                    name: 'Переменные',
                    component: Variables
                },
                {
                    path: 'wallet-histories',
                    name: 'История оплат',
                    component: WalletHistory
                },

            ],
            meta: {
                middlewareAuth: true,
            },
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                }
            ]
        }
    ]
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.middlewareAuth)) {
        if (!window.auth.check()) {
            next({
                path: '/pages/login',
                query: {redirect: to.fullPath}
            });
            return;
        }
    }
    next();
})
export default router;
