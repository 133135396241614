var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',{staticClass:"clapans"},[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 d-flex justify-content-end"}),_c('div',{staticClass:"col-md-6 d-flex justify-content-end align-items-center"},[_c('span',{staticClass:"font-lg alert-success mr-4"},[_vm._v(" "+_vm._s(_vm.all_sum)+" Общий долг: "),_c('b',[_vm._v(_vm._s(_vm.all_sum)+" - "+_vm._s(_vm.all_sum_wallet)+" = "+_vm._s(_vm.all_sum - _vm.all_sum_wallet))])]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Внести оплату ")])],1)])]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","column-filter":"","table-filter":"","sorter":"","tableFilter":{'label':'Поиск','placeholder':'Введите слово для поиска'}},scopedSlots:_vm._u([{key:"number_of_birca",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.number_of_birca, item)))])}},{key:"weight",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.weight, item)))])}},{key:"code_of_client",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.code_of_client, item)))])}},{key:"name_of_client",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.name_of_client, item)))])}},{key:"naim",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.naim, item)))])}},{key:"upakovka",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.upakovka, item)))])}},{key:"address",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.address, item)))])}},{key:"type_of_race",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.type_of_race, item)))])}},{key:"race_number",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.race_number, item)))])}},{key:"price_per_kg",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.price_per_kg, item)))])}},{key:"price_per_place",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(_vm._s(_vm.filterNull(item.price_per_place, item)))])}},{key:"date_of_traffic",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.distanceFromNow(item.date_of_traffic))+" ")])}},{key:"summa",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(" "+_vm._s(item.action == 'oplata' ? '' : item.summa)+" ")])}},{key:"oplata",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_vm._v(" "+_vm._s(item.action == 'oplata' ? '- ' + item.summa : '')+" ")])}},{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"d-flex"},[(item.action!='oplata')?[_c('CButton',{staticClass:"mr-2",attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteItem(item,index)}}},[_vm._v(" Удалить ")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.viewItem(item,index)}}},[_vm._v(" Посмотреть ")])]:[_c('CButton',{staticClass:"mr-2",attrs:{"color":"danger"},on:{"click":function($event){return _vm.deletePay(item,index)}}},[_vm._v(" Удалить ")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.updatePay(item,index)}}},[_vm._v(" Изменить ")])]],2)}}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_vm._v(" Количество мест: "+_vm._s(_vm.tableItems.length)+" | Оплата: "+_vm._s(_vm.all_sum_wallet)+" ")])])],1)],1)],1)],1),_c('CModal',{attrs:{"title":"Внесение оплаты","show":_vm.historyModal},on:{"update:show":function($event){_vm.historyModal=$event}}},[_c('CCard',[_c('CCardBody',[_c('CForm',{on:{"submit":function($event){return _vm.submitForm()}}},[_c('CInput',{attrs:{"type":"hidden","horizontal":""},model:{value:(_vm.history.id),callback:function ($$v) {_vm.$set(_vm.history, "id", $$v)},expression:"history.id"}}),_c('CInput',{attrs:{"type":"text","label":"Значение","horizontal":""},model:{value:(_vm.history.value),callback:function ($$v) {_vm.$set(_vm.history, "value", $$v)},expression:"history.value"}}),_c('CButton',{attrs:{"color":"success","type":"submit"}},[_vm._v(" Сохранить ")])],1)],1)],1)],1),_c('div',{staticClass:"alert_block"},[(_vm.warning)?_c('CAlert',{attrs:{"color":"warning","closeButton":""}},[_vm._v(" "+_vm._s(_vm.alert_message)+" ")]):_vm._e(),(_vm.success)?_c('CAlert',{attrs:{"color":"primary","closeButton":""}},[_vm._v(" "+_vm._s(_vm.alert_message)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }